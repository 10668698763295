import { Device } from 'entities/Device';
import { Installation } from 'entities/Installation';
import { Language } from 'entities/Language';

import ru_mac_config_1 from 'shared/assets/images/guides/ru/mac/config/1.png';
import ru_mac_config_2 from 'shared/assets/images/guides/ru/mac/config/2.png';
import ru_mac_config_3 from 'shared/assets/images/guides/ru/mac/config/3.png';
import ru_mac_config_4 from 'shared/assets/images/guides/ru/mac/config/4.png';
import ru_mac_config_5 from 'shared/assets/images/guides/ru/mac/config/5.png';
import ru_mac_config_6 from 'shared/assets/images/guides/ru/mac/config/6.png';
import ru_mac_config_7 from 'shared/assets/images/guides/ru/mac/config/7.png';
import ru_mac_config_8 from 'shared/assets/images/guides/ru/mac/config/8.png';

import ru_mac_manual_1 from 'shared/assets/images/guides/ru/mac/manual/1.png';
import ru_mac_manual_2 from 'shared/assets/images/guides/ru/mac/manual/2.png';
import ru_mac_manual_3 from 'shared/assets/images/guides/ru/mac/manual/3.png';
import ru_mac_manual_4 from 'shared/assets/images/guides/ru/mac/manual/4.png';
import ru_mac_manual_5 from 'shared/assets/images/guides/ru/mac/manual/5.png';

import ru_windows_config_1 from 'shared/assets/images/guides/ru/windows/config/1.png';
import ru_windows_config_2 from 'shared/assets/images/guides/ru/windows/config/2.png';
import ru_windows_config_3 from 'shared/assets/images/guides/ru/windows/config/3.png';
import ru_windows_config_4 from 'shared/assets/images/guides/ru/windows/config/4.png';
import ru_windows_config_5 from 'shared/assets/images/guides/ru/windows/config/5.png';

import ru_windows_manual_1 from 'shared/assets/images/guides/ru/windows/manual/1.png';
import ru_windows_manual_2 from 'shared/assets/images/guides/ru/windows/manual/2.png';
import ru_windows_manual_3 from 'shared/assets/images/guides/ru/windows/manual/3.png';
import ru_windows_manual_4 from 'shared/assets/images/guides/ru/windows/manual/4.png';
import ru_windows_manual_5 from 'shared/assets/images/guides/ru/windows/manual/5.png';

import ru_iphone_config_1 from 'shared/assets/images/guides/ru/iphone/config/1.png';
import ru_iphone_config_2 from 'shared/assets/images/guides/ru/iphone/config/2.png';
import ru_iphone_config_3 from 'shared/assets/images/guides/ru/iphone/config/3.png';
import ru_iphone_config_4 from 'shared/assets/images/guides/ru/iphone/config/4.png';
import ru_iphone_config_5 from 'shared/assets/images/guides/ru/iphone/config/5.png';
import ru_iphone_config_6 from 'shared/assets/images/guides/ru/iphone/config/6.png';
import ru_iphone_config_7 from 'shared/assets/images/guides/ru/iphone/config/7.png';
import ru_iphone_config_8 from 'shared/assets/images/guides/ru/iphone/config/8.png';
import ru_iphone_config_9 from 'shared/assets/images/guides/ru/iphone/config/9.png';
import ru_iphone_config_10 from 'shared/assets/images/guides/ru/iphone/config/10.png';
import ru_iphone_config_11 from 'shared/assets/images/guides/ru/iphone/config/11.png';
import ru_iphone_config_12 from 'shared/assets/images/guides/ru/iphone/config/12.png';
import ru_iphone_config_13 from 'shared/assets/images/guides/ru/iphone/config/13.png';

import ru_iphone_manual_1 from 'shared/assets/images/guides/ru/iphone/manual/1.png';
import ru_iphone_manual_2 from 'shared/assets/images/guides/ru/iphone/manual/2.png';
import ru_iphone_manual_3 from 'shared/assets/images/guides/ru/iphone/manual/3.png';
import ru_iphone_manual_4 from 'shared/assets/images/guides/ru/iphone/manual/4.png';
import ru_iphone_manual_5 from 'shared/assets/images/guides/ru/iphone/manual/5.png';
import ru_iphone_manual_6 from 'shared/assets/images/guides/ru/iphone/manual/6.png';
import ru_iphone_manual_7 from 'shared/assets/images/guides/ru/iphone/manual/7.png';

import ru_android_config_1 from 'shared/assets/images/guides/ru/android/config/1.png';
import ru_android_config_2 from 'shared/assets/images/guides/ru/android/config/2.png';
import ru_android_config_3 from 'shared/assets/images/guides/ru/android/config/3.png';
import ru_android_config_4 from 'shared/assets/images/guides/ru/android/config/4.png';
import ru_android_config_5 from 'shared/assets/images/guides/ru/android/config/5.png';
import ru_android_config_6 from 'shared/assets/images/guides/ru/android/config/6.png';
import ru_android_config_7 from 'shared/assets/images/guides/ru/android/config/7.png';
import ru_android_config_8 from 'shared/assets/images/guides/ru/android/config/8.png';
import ru_android_config_9 from 'shared/assets/images/guides/ru/android/config/9.png';
import ru_android_config_10 from 'shared/assets/images/guides/ru/android/config/10.png';
import ru_android_config_11 from 'shared/assets/images/guides/ru/android/config/11.png';
import ru_android_config_12 from 'shared/assets/images/guides/ru/android/config/12.png';
import ru_android_config_13 from 'shared/assets/images/guides/ru/android/config/13.png';
import ru_android_config_14 from 'shared/assets/images/guides/ru/android/config/14.png';
import ru_android_config_15 from 'shared/assets/images/guides/ru/android/config/15.png';

import ru_android_manual_1 from 'shared/assets/images/guides/ru/android/manual/1.png';
import ru_android_manual_2 from 'shared/assets/images/guides/ru/android/manual/2.png';
import ru_android_manual_3 from 'shared/assets/images/guides/ru/android/manual/3.png';
import ru_android_manual_4 from 'shared/assets/images/guides/ru/android/manual/4.png';
import ru_android_manual_5 from 'shared/assets/images/guides/ru/android/manual/5.png';
import ru_android_manual_6 from 'shared/assets/images/guides/ru/android/manual/6.png';

import en_mac_config_1 from 'shared/assets/images/guides/en/mac/config/1.png';
import en_mac_config_2 from 'shared/assets/images/guides/en/mac/config/2.png';
import en_mac_config_3 from 'shared/assets/images/guides/en/mac/config/3.png';
import en_mac_config_4 from 'shared/assets/images/guides/en/mac/config/4.png';
import en_mac_config_5 from 'shared/assets/images/guides/en/mac/config/5.png';
import en_mac_config_6 from 'shared/assets/images/guides/en/mac/config/6.png';
import en_mac_config_7 from 'shared/assets/images/guides/en/mac/config/7.png';
import en_mac_config_8 from 'shared/assets/images/guides/en/mac/config/8.png';

import en_mac_manual_1 from 'shared/assets/images/guides/en/mac/manual/1.png';
import en_mac_manual_2 from 'shared/assets/images/guides/en/mac/manual/2.png';
import en_mac_manual_3 from 'shared/assets/images/guides/en/mac/manual/3.png';
import en_mac_manual_4 from 'shared/assets/images/guides/en/mac/manual/4.png';
import en_mac_manual_5 from 'shared/assets/images/guides/en/mac/manual/5.png';

import en_windows_config_1 from 'shared/assets/images/guides/en/windows/config/1.png';
import en_windows_config_2 from 'shared/assets/images/guides/en/windows/config/2.png';
import en_windows_config_3 from 'shared/assets/images/guides/en/windows/config/3.png';
import en_windows_config_4 from 'shared/assets/images/guides/en/windows/config/4.png';
import en_windows_config_5 from 'shared/assets/images/guides/en/windows/config/5.png';

import en_windows_manual_1 from 'shared/assets/images/guides/en/windows/manual/1.png';
import en_windows_manual_2 from 'shared/assets/images/guides/en/windows/manual/2.png';
import en_windows_manual_3 from 'shared/assets/images/guides/en/windows/manual/3.png';
import en_windows_manual_4 from 'shared/assets/images/guides/en/windows/manual/4.png';
import en_windows_manual_5 from 'shared/assets/images/guides/en/windows/manual/5.png';

import en_iphone_config_1 from 'shared/assets/images/guides/en/iphone/config/1.png';
import en_iphone_config_2 from 'shared/assets/images/guides/en/iphone/config/2.png';
import en_iphone_config_3 from 'shared/assets/images/guides/en/iphone/config/3.png';
import en_iphone_config_4 from 'shared/assets/images/guides/en/iphone/config/4.png';
import en_iphone_config_5 from 'shared/assets/images/guides/en/iphone/config/5.png';
import en_iphone_config_6 from 'shared/assets/images/guides/en/iphone/config/6.png';
import en_iphone_config_7 from 'shared/assets/images/guides/en/iphone/config/7.png';
import en_iphone_config_8 from 'shared/assets/images/guides/en/iphone/config/8.png';
import en_iphone_config_9 from 'shared/assets/images/guides/en/iphone/config/9.png';
import en_iphone_config_10 from 'shared/assets/images/guides/en/iphone/config/10.png';
import en_iphone_config_11 from 'shared/assets/images/guides/en/iphone/config/11.png';
import en_iphone_config_12 from 'shared/assets/images/guides/en/iphone/config/12.png';
import en_iphone_config_13 from 'shared/assets/images/guides/en/iphone/config/13.png';

import en_iphone_manual_1 from 'shared/assets/images/guides/en/iphone/manual/1.png';
import en_iphone_manual_2 from 'shared/assets/images/guides/en/iphone/manual/2.png';
import en_iphone_manual_3 from 'shared/assets/images/guides/en/iphone/manual/3.png';
import en_iphone_manual_4 from 'shared/assets/images/guides/en/iphone/manual/4.png';
import en_iphone_manual_5 from 'shared/assets/images/guides/en/iphone/manual/5.png';
import en_iphone_manual_6 from 'shared/assets/images/guides/en/iphone/manual/6.png';
import en_iphone_manual_7 from 'shared/assets/images/guides/en/iphone/manual/7.png';

import en_android_config_1 from 'shared/assets/images/guides/en/android/config/1.png';
import en_android_config_2 from 'shared/assets/images/guides/en/android/config/2.png';
import en_android_config_3 from 'shared/assets/images/guides/en/android/config/3.png';
import en_android_config_4 from 'shared/assets/images/guides/en/android/config/4.png';
import en_android_config_5 from 'shared/assets/images/guides/en/android/config/5.png';
import en_android_config_6 from 'shared/assets/images/guides/en/android/config/6.png';
import en_android_config_7 from 'shared/assets/images/guides/en/android/config/7.png';
import en_android_config_8 from 'shared/assets/images/guides/en/android/config/8.png';
import en_android_config_9 from 'shared/assets/images/guides/en/android/config/9.png';
import en_android_config_10 from 'shared/assets/images/guides/en/android/config/10.png';
import en_android_config_11 from 'shared/assets/images/guides/en/android/config/11.png';
import en_android_config_12 from 'shared/assets/images/guides/en/android/config/12.png';
import en_android_config_13 from 'shared/assets/images/guides/en/android/config/13.png';
import en_android_config_14 from 'shared/assets/images/guides/en/android/config/14.png';
import en_android_config_15 from 'shared/assets/images/guides/en/android/config/15.png';

import en_android_manual_1 from 'shared/assets/images/guides/en/android/manual/1.png';
import en_android_manual_2 from 'shared/assets/images/guides/en/android/manual/2.png';
import en_android_manual_3 from 'shared/assets/images/guides/en/android/manual/3.png';
import en_android_manual_4 from 'shared/assets/images/guides/en/android/manual/4.png';
import en_android_manual_5 from 'shared/assets/images/guides/en/android/manual/5.png';
import en_android_manual_6 from 'shared/assets/images/guides/en/android/manual/6.png';

import en_mac_app_1_1 from 'shared/assets/images/guides/en/mac/app/1.1.png';
import en_mac_app_1_2 from 'shared/assets/images/guides/en/mac/app/1.2.png';
import en_mac_app_2 from 'shared/assets/images/guides/en/mac/app/2.png';
import en_mac_app_3 from 'shared/assets/images/guides/en/mac/app/3.png';
import en_mac_app_4 from 'shared/assets/images/guides/en/mac/app/4.png';
import en_mac_app_5 from 'shared/assets/images/guides/en/mac/app/5.png';

import ru_mac_app_1_1 from 'shared/assets/images/guides/ru/mac/app/1.1.png';
import ru_mac_app_1_2 from 'shared/assets/images/guides/ru/mac/app/1.2.png';
import ru_mac_app_2 from 'shared/assets/images/guides/ru/mac/app/2.png';
import ru_mac_app_3 from 'shared/assets/images/guides/ru/mac/app/3.png';
import ru_mac_app_4 from 'shared/assets/images/guides/ru/mac/app/4.png';
import ru_mac_app_5 from 'shared/assets/images/guides/ru/mac/app/5.png';

import ru_windows_app_1 from 'shared/assets/images/guides/ru/windows/app/1.png';
import ru_windows_app_2 from 'shared/assets/images/guides/ru/windows/app/2.png';
import ru_windows_app_3 from 'shared/assets/images/guides/ru/windows/app/3.png';
import ru_windows_app_4 from 'shared/assets/images/guides/ru/windows/app/4.png';
import ru_windows_app_5 from 'shared/assets/images/guides/ru/windows/app/5.png';

import en_windows_app_1 from 'shared/assets/images/guides/en/windows/app/1.png';
import en_windows_app_2 from 'shared/assets/images/guides/en/windows/app/2.png';
import en_windows_app_3 from 'shared/assets/images/guides/en/windows/app/3.png';
import en_windows_app_4 from 'shared/assets/images/guides/en/windows/app/4.png';
import en_windows_app_5 from 'shared/assets/images/guides/en/windows/app/5.png';

import en_linux_app_1 from 'shared/assets/images/guides/en/linux/app/1.png';
import linux_app_2 from 'shared/assets/images/guides/en/linux/app/2.png';
import linux_app_3 from 'shared/assets/images/guides/en/linux/app/3.png';
import linux_app_4 from 'shared/assets/images/guides/en/linux/app/4.png';

import ru_linux_app_1 from 'shared/assets/images/guides/ru/linux/app/1.png';

import en_iphone_app_1 from 'shared/assets/images/guides/en/iphone/app/1.png';
import en_iphone_app_2 from 'shared/assets/images/guides/en/iphone/app/2.png';
import en_iphone_app_3 from 'shared/assets/images/guides/en/iphone/app/3.png';
import en_iphone_app_4 from 'shared/assets/images/guides/en/iphone/app/4.png';
import en_iphone_app_5 from 'shared/assets/images/guides/en/iphone/app/5.png';

import ru_iphone_app_1 from 'shared/assets/images/guides/ru/iphone/app/1.png';
import ru_iphone_app_2 from 'shared/assets/images/guides/ru/iphone/app/2.png';
import ru_iphone_app_3 from 'shared/assets/images/guides/ru/iphone/app/3.png';
import ru_iphone_app_4 from 'shared/assets/images/guides/ru/iphone/app/4.png';
import ru_iphone_app_5 from 'shared/assets/images/guides/ru/iphone/app/5.png';

import en_android_app_1 from 'shared/assets/images/guides/en/android/app/1.png';
import en_android_app_2 from 'shared/assets/images/guides/en/android/app/2.png';
import en_android_app_3 from 'shared/assets/images/guides/en/android/app/3.png';
import en_android_app_4 from 'shared/assets/images/guides/en/android/app/4.png';
import en_android_app_5 from 'shared/assets/images/guides/en/android/app/5.png';

import ru_android_app_1 from 'shared/assets/images/guides/ru/android/app/1.png';
import ru_android_app_2 from 'shared/assets/images/guides/ru/android/app/2.png';
import ru_android_app_3 from 'shared/assets/images/guides/ru/android/app/3.png';
import ru_android_app_4 from 'shared/assets/images/guides/ru/android/app/4.png';
import ru_android_app_5 from 'shared/assets/images/guides/ru/android/app/5.png';

const images: Record<
  Language,
  Record<Device, Record<Installation, Record<number, string | string[]>>>
> = {
  ru: {
    mac: {
      app: {
        1: [ru_mac_app_1_1, ru_mac_app_1_2],
        2: ru_mac_app_2,
        3: ru_mac_app_3,
        4: ru_mac_app_4,
        5: ru_mac_app_5,
      },
      config: {
        1: ru_mac_config_1,
        2: ru_mac_config_2,
        3: ru_mac_config_3,
        4: ru_mac_config_4,
        5: ru_mac_config_5,
        6: ru_mac_config_6,
        7: ru_mac_config_7,
        8: ru_mac_config_8,
      },
      manual: {
        1: ru_mac_manual_1,
        2: ru_mac_manual_2,
        3: ru_mac_manual_3,
        4: ru_mac_manual_4,
        5: ru_mac_manual_5,
      },
    },
    windows: {
      app: {
        1: ru_windows_app_1,
        2: ru_windows_app_2,
        3: ru_windows_app_3,
        4: ru_windows_app_4,
        5: ru_windows_app_5,
      },
      config: {
        1: ru_windows_config_1,
        2: ru_windows_config_2,
        3: ru_windows_config_3,
        4: ru_windows_config_4,
        5: ru_windows_config_5,
      },
      manual: {
        1: ru_windows_manual_1,
        2: ru_windows_manual_2,
        3: ru_windows_manual_3,
        4: ru_windows_manual_4,
        5: ru_windows_manual_5,
      },
    },
    linux: {
      app: {
        1: ru_linux_app_1,
        2: linux_app_2,
        3: linux_app_3,
        4: linux_app_4,
      },
      config: {
      },
      manual: {
      },
    },
    iphone: {
      app: {
        1: ru_iphone_app_1,
        2: ru_iphone_app_2,
        3: ru_iphone_app_3,
        4: ru_iphone_app_4,
        5: ru_iphone_app_5,
      },
      config: {
        1: ru_iphone_config_1,
        2: ru_iphone_config_2,
        3: ru_iphone_config_3,
        4: ru_iphone_config_4,
        5: ru_iphone_config_5,
        6: ru_iphone_config_6,
        7: ru_iphone_config_7,
        8: ru_iphone_config_8,
        9: ru_iphone_config_9,
        10: ru_iphone_config_10,
        11: ru_iphone_config_11,
        12: ru_iphone_config_12,
        13: ru_iphone_config_13,
      },
      manual: {
        1: ru_iphone_manual_1,
        2: ru_iphone_manual_2,
        3: ru_iphone_manual_3,
        4: ru_iphone_manual_4,
        5: ru_iphone_manual_5,
        6: ru_iphone_manual_6,
        7: ru_iphone_manual_7,
      },
    },
    android: {
      app: {
        1: ru_android_app_1,
        2: ru_android_app_2,
        3: ru_android_app_3,
        4: ru_android_app_4,
        5: ru_android_app_5,
      },
      config: {
        1: ru_android_config_1,
        2: ru_android_config_2,
        3: ru_android_config_3,
        4: ru_android_config_4,
        5: ru_android_config_5,
        6: ru_android_config_6,
        7: ru_android_config_7,
        8: ru_android_config_8,
        9: ru_android_config_9,
        10: ru_android_config_10,
        11: ru_android_config_11,
        12: ru_android_config_12,
        13: ru_android_config_13,
        14: ru_android_config_14,
        15: ru_android_config_15,
      },
      manual: {
        1: ru_android_manual_1,
        2: ru_android_manual_2,
        3: ru_android_manual_3,
        4: ru_android_manual_4,
        5: ru_android_manual_5,
        6: ru_android_manual_6,
      },
    },
  },
  en: {
    mac: {
      app: {
        1: [en_mac_app_1_1, en_mac_app_1_2],
        2: en_mac_app_2,
        3: en_mac_app_3,
        4: en_mac_app_4,
        5: en_mac_app_5,
      },
      config: {
        1: en_mac_config_1,
        2: en_mac_config_2,
        3: en_mac_config_3,
        4: en_mac_config_4,
        5: en_mac_config_5,
        6: en_mac_config_6,
        7: en_mac_config_7,
        8: en_mac_config_8,
      },
      manual: {
        1: en_mac_manual_1,
        2: en_mac_manual_2,
        3: en_mac_manual_3,
        4: en_mac_manual_4,
        5: en_mac_manual_5,
      },
    },
    windows: {
      app: {
        1: en_windows_app_1,
        2: en_windows_app_2,
        3: en_windows_app_3,
        4: en_windows_app_4,
        5: en_windows_app_5,
      },
      config: {
        1: en_windows_config_1,
        2: en_windows_config_2,
        3: en_windows_config_3,
        4: en_windows_config_4,
        5: en_windows_config_5,
      },
      manual: {
        1: en_windows_manual_1,
        2: en_windows_manual_2,
        3: en_windows_manual_3,
        4: en_windows_manual_4,
        5: en_windows_manual_5,
      },
    },
    linux: {
      app: {
        1: en_linux_app_1,
        2: linux_app_2,
        3: linux_app_3,
        4: linux_app_4,
      },
      config: {
      },
      manual: {
      },
    },
    iphone: {
      app: {
        1: en_iphone_app_1,
        2: en_iphone_app_2,
        3: en_iphone_app_3,
        4: en_iphone_app_4,
        5: en_iphone_app_5,
      },
      config: {
        1: en_iphone_config_1,
        2: en_iphone_config_2,
        3: en_iphone_config_3,
        4: en_iphone_config_4,
        5: en_iphone_config_5,
        6: en_iphone_config_6,
        7: en_iphone_config_7,
        8: en_iphone_config_8,
        9: en_iphone_config_9,
        10: en_iphone_config_10,
        11: en_iphone_config_11,
        12: en_iphone_config_12,
        13: en_iphone_config_13,
      },
      manual: {
        1: en_iphone_manual_1,
        2: en_iphone_manual_2,
        3: en_iphone_manual_3,
        4: en_iphone_manual_4,
        5: en_iphone_manual_5,
        6: en_iphone_manual_6,
        7: en_iphone_manual_7,
      },
    },
    android: {
      app: {
        1: en_android_app_1,
        2: en_android_app_2,
        3: en_android_app_3,
        4: en_android_app_4,
        5: en_android_app_5,
      },
      config: {
        1: en_android_config_1,
        2: en_android_config_2,
        3: en_android_config_3,
        4: en_android_config_4,
        5: en_android_config_5,
        6: en_android_config_6,
        7: en_android_config_7,
        8: en_android_config_8,
        9: en_android_config_9,
        10: en_android_config_10,
        11: en_android_config_11,
        12: en_android_config_12,
        13: en_android_config_13,
        14: en_android_config_14,
        15: en_android_config_15,
      },
      manual: {
        1: en_android_manual_1,
        2: en_android_manual_2,
        3: en_android_manual_3,
        4: en_android_manual_4,
        5: en_android_manual_5,
        6: en_android_manual_6,
      },
    },
  },
};

export const getImageByParams = (
  device: Device,
  type: Installation,
  language: Language
) => {
  return (number: number) => {
    return images[language][device][type][number];
  };
};
