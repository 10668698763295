import React from 'react';
import styles from './styles.module.scss';
import { GuideSlide } from 'entities/Guide';
import {
  Arrow,
  Button,
  Copy,
  Download,
  Flex,
  QueryStatusBoundary,
  Typography,
} from 'shared/_ui';
import { useTranslation } from 'react-i18next';
import { useSliderContext } from '../../model';
import classNames from 'classnames';
import { getInstructionByParams } from './lib';
import { useCreateConnection } from 'features/CreateConnection';
import { useFetchUserInfo, useFetchXrayConnections } from 'entities/Connection';
import { useFetchCountries } from 'entities/Country';
import { useFetchAccessServers } from 'entities/Server';
import { usePrevious } from '@uidotdev/usehooks';
import { useConnectionDevices } from 'entities/Device';
import { DownloadConfig } from 'features/DownloadConfig';
import { useCreateXrayConnection } from 'features/CreateXrayConnection';
import { notify, useMatchMedia } from 'shared';
import { useStoreUiControls } from 'shared/lib';
import { CONTROL_IDS } from 'shared/consts';
import { SlideProps } from 'entities/Guide';

const InstructionSlide: React.FC<SlideProps> = ({
  className,
  isActive,
  number
}) => {
  const { t } = useTranslation();

  const isCreated = React.useRef<boolean>(false);

  const [create, { isError: isCreateError }] = useCreateConnection();
  const [createXray, { isError: isCreateXrayError }] =
    useCreateXrayConnection();
  const { isLoading: isFetchUserInfoLoading, data: userInfo } =
    useFetchUserInfo(null);
  const { isLoading: isFetchCountriesLoading, data: countries } =
    useFetchCountries(null);
  const { data: xrayConnections } = useFetchXrayConnections(null);
  const { isLoading: isFetchServersLoading, data: servers } =
    useFetchAccessServers(null);
  const { setIsOpen } = useStoreUiControls(CONTROL_IDS.GUIDE);

  const prevConnections = usePrevious(userInfo?.connections);
  const prevXrayConnections = usePrevious(xrayConnections);

  const { isMobile } = useMatchMedia();

  const {
    device,
    chosenServerId,
    installationType,
    setXrayConnectionLink,
    xrayConnectionLink,
    next,
    prev,
    isConnectionCreate,
  } = useSliderContext();

  const prevDevice = usePrevious(device);

  const { createDevice, getDevice, changeDevice } = useConnectionDevices();
  const Instruction = getInstructionByParams(device, installationType);

  //const [editCountry, { isLoading: isEditLoading }] = useEditCountries();
  const createConnection = async () => {
    const firstCountry = countries?.[0];
    if (!firstCountry) return;

    const idConverter = [1,1,4,1,5,2,6];
    await create(idConverter[chosenServerId || firstCountry.id]).unwrap()
    isCreated.current = true;
  };

  const createXrayConnection = async () => {
    const firstCountry = countries?.[0];
    if (!firstCountry) return;
    try {
      const result = await createXray(
        chosenServerId || firstCountry.id
      ).unwrap();

      if (result) {
        setXrayConnectionLink(result.subscription);
      }
    } catch (error) {
      notify.error(t('xray.createError'));
    }

    isCreated.current = true;
  };

  React.useEffect(() => {
    if (
      !userInfo ||
      !countries?.length ||
      (installationType !== 'app' && isCreated.current)
    )
      return;

    const isLimit =
      userInfo?.connections.length + (xrayConnections?.length || 0) >=
      userInfo?.tariff.numberOfAccounts;

    if (isConnectionCreate && isActive && !isLimit) {
      switch (installationType) {
        case 'config': {
          createConnection();
          break;
        }
        case 'manual': {
          createConnection();
          break;
        }
        case 'app': {
          if (
            xrayConnections?.some(
              (connection) => connection.serverId === chosenServerId
            )
          ) {
            notify.info(t('xray.duplicateConnection'));
            setTimeout(() => setIsOpen(false), 1000);

            break;
          }
          createXrayConnection();
          break;
        }
      }
    }
  }, [isConnectionCreate, isActive, userInfo, countries]);

  React.useEffect(() => {
    if (!prevConnections || !userInfo) return;

    const lastConnection = userInfo.connections.at(-1);

    if (!lastConnection) return;

    if (prevConnections.length < userInfo.connections.length) {
      createDevice(lastConnection.id, device);
    }
  }, [prevConnections, userInfo, device]);

  React.useEffect(() => {
    if (!prevXrayConnections || !xrayConnections) return;

    const lastConnection = xrayConnections.at(-1);

    if (!lastConnection) return;

    if (prevXrayConnections.length < xrayConnections.length) {
      createDevice(lastConnection.id, device);
    }
  }, [prevXrayConnections, xrayConnections, device]);

  React.useEffect(() => {
    if (!prevConnections || !userInfo || !isConnectionCreate) return;

    const lastConnection = userInfo.connections.at(-1);

    if (!lastConnection) return;

    if (!getDevice(lastConnection.id)) return;

    if (prevDevice !== device) {
      changeDevice(lastConnection.id, device);
    }
  }, [prevConnections, userInfo, device, getDevice, isConnectionCreate]);

  const lastConnection = userInfo?.connections.at(-1);

  const connectionPlaceholder = {
    id: 0,
    name: t('connection.name'),
    server: t('connection.server'),
    login: t('connection.login'),
    password: t('connection.password'),
  };

  const connection = {
    id: lastConnection?.id ?? connectionPlaceholder.id,
    server: servers?.[0].server ?? connectionPlaceholder.server,
    name: connectionPlaceholder.name,
    login: lastConnection?.login ?? connectionPlaceholder.login,
    password: lastConnection?.password ?? connectionPlaceholder.password,
  };

  return (
    <GuideSlide
      className={classNames(className, styles.slide)}
      head={
        <Flex direction="column" spacing={16}>
          <Flex spacing={20}>
            <Flex className={styles.number} align="center" justify="center">
              <Typography.Text size={6}>{number}</Typography.Text>
            </Flex>
            <Typography.Title level={2}>
              {t(`guide.${installationType}.title`)}
            </Typography.Title>
          </Flex>
          <Typography.Text
            className={styles.description}
            type="secondary"
            size={4}
          >
            {t('guide.description')}:
          </Typography.Text>
        </Flex>
      }
      body={<></>}
      controls={
        <Flex justify="space-between" spacing={10}>
          <Button
            onClick={prev}
            className={styles.back}
            type="secondary"
            prefix={<Arrow className={styles.arrow} />}
          >
            {t('shared.text.back')}
          </Button>
          <Button
            onClick={() => {
              next();
            }}
            className={styles.next}
            type="primary"
          >
            {t('shared.text.finish')}!
          </Button>
        </Flex>
      }
      footer={
        <Flex direction="column" spacing={36}>
          {installationType === 'app' && (
            <Flex direction={isMobile ? 'column' : 'row'} spacing={16}>
              {device === 'windows' && (
                <>
                  <Button
                    size="large"
                    className={styles.button}
                    prefix={<Download className={styles.download} />}
                    type="secondary"
                    onClick={() =>
                      window.open(
                        'https://github.com/hiddify/HiddifyN/releases/download/v3.0.0/win-x64.zip'
                      )
                    }
                  >
                    {t('guide.app.hiddifynext')}
                  </Button>
                </>
              )}
              {device === 'linux' && (
                <>
                  <Button
                    size="large"
                    className={styles.button}
                    prefix={<Download className={styles.download} />}
                    type="secondary"
                    onClick={() =>
                      window.open(
                        'https://github.com/hiddify/hiddify-app/releases/latest/download/Hiddify-Linux-x64.AppImage'
                      )
                    }
                  >
                    {t('guide.app.hiddifynext')}
                  </Button>
                </>
              )}
              {device === 'mac' && (
                <>
                  <Button
                    size="large"
                    className={styles.button}
                    prefix={<Download className={styles.download} />}
                    type="secondary"
                    onClick={() => {
                      window.open(
                        'https://apps.apple.com/us/app/foxray/id6448898396'
                      );
                    }}
                  >
                    {t('guide.app.foxray')}
                  </Button>
                </>
              )}
              {device === 'iphone' && (
                <>
                  <Button
                    size="large"
                    className={styles.button}
                    prefix={<Download className={styles.download} />}
                    type="secondary"
                    onClick={() => {
                      window.open(
                        'https://apps.apple.com/us/app/foxray/id644889839'
                      );
                    }}
                  >
                    {t('guide.foxray')}
                  </Button>
                </>
              )}
              {device === 'android' && (
                <>
                  <Button
                    size="large"
                    className={styles.button}
                    prefix={<Download className={styles.download} />}
                    type="secondary"
                    onClick={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=app.hiddify.com&hl=en_US'
                      )
                    }
                  >
                    {t('guide.app.hiddifynext')}
                  </Button>
                  <Button
                    size="large"
                    className={styles.button}
                    prefix={<Download className={styles.download} />}
                    type="secondary"
                    onClick={() =>
                      window.open(
                        'https://github.com/hiddify/HiddifyNG/releases/download/v6.0.4/HiddifyNG.apk'
                      )
                    }
                  >
                    {`${t('guide.app.hiddifynext')} Huawei`}
                  </Button>
                </>
              )}

              {xrayConnectionLink && (
                <Copy
                  className={styles.link}
                  displayText={t('guide.app.linkKey')}
                  truncate
                  variant="filled"
                  text={xrayConnectionLink}
                />
              )}
            </Flex>
          )}
          {device !== 'windows' && installationType === 'config' && (
            <Flex spacing={16}>
              {servers && lastConnection && (
                <DownloadConfig
                  device={device}
                  server={servers[0].server}
                  connectionId={lastConnection.id}
                />
              )}
              {device === 'android' && (
                <Button
                  size="large"
                  className={styles.button}
                  prefix={<Download className={styles.download} />}
                  type="secondary"
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=org.strongswan.android',
                      '_blank'
                    )
                  }
                >
                  {t('guide.config.downloadApp')}
                </Button>
              )}
            </Flex>
          )}
          <Typography.Text type="secondary" size={4}>
            {t('guide.stepByStep')}:
          </Typography.Text>
          {isConnectionCreate ? (
            <QueryStatusBoundary
              isLoading={
                isFetchUserInfoLoading ||
                isFetchCountriesLoading ||
                isFetchServersLoading
              }
              isError={isCreateError || isCreateXrayError}
              errorMessage={t('connection.error.create')}
            >
              <Instruction connection={connection} />
            </QueryStatusBoundary>
          ) : (
            <Instruction connection={connectionPlaceholder} />
          )}
        </Flex>
      }
    />
  );
};

export { InstructionSlide };
